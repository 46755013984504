import useBreakpoint from "./useBreakpoint";

export default function useHeaderBreakpointOffsets() {
  const isMobile = useBreakpoint(991);
  const top = isMobile ? 172 : 66;
  return {
    top: `${top}px`,
    bottom: isMobile ? "50%" : `78%`,
  };
}
