import classNames from "classnames";
import { useContext, useState } from "react";
import Icon from "./icon";
import useBreakpoint from "../hooks/useBreakpoint";
import { Swiper, SwiperSlide } from "swiper/react";
import LanguageContext from "../context/language";
import { HomeScreenPromptContext } from "../context/home-screen-prompt";

const IndexBar = ({ waypoints, activeWaypoint, setActiveWaypoint, className = "" }) => {
  const { showHomeScreenPrompt } = useContext(HomeScreenPromptContext);
  const isMobile = useBreakpoint(767);
  const [open, setOpen] = useState(false);
  const { labels } = useContext(LanguageContext);
  const waypointIndex = waypoints.findIndex((w) => w.value === activeWaypoint);

  if (waypoints.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(
        "index-bar",
        {
          "index-bar--pwa-banner": showHomeScreenPrompt,
        },
        className
      )}
    >
      <button
        className={classNames(
          "index-bar__accordion",
          activeWaypoint !== "" && open && "index-bar__accordion--open"
        )}
        onClick={() => setOpen((current) => !current)}
      >
        <div className="index-bar__panel">
          {activeWaypoint !== "" && (
            <span className="index-bar__index">
              {waypointIndex >= 9 ? waypointIndex + 1 : `0${waypointIndex + 1}`}.
            </span>
          )}
          <div className="index-bar__title">
            {activeWaypoint !== "" ? waypoints[waypointIndex].label : labels.INDEX ?? "Index"}
          </div>
        </div>
        <Icon name="chevron-right" />
      </button>
      {open && activeWaypoint && (
        <span className="index-bar__index index-bar__index--white">{labels.INDEX ?? "Index"}</span>
      )}
      {(open || !isMobile) && (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={false}
          watchOverflow={true}
          enabled={false}
          breakpoints={{
            767: {
              enabled: true,
              slidesPerView: "auto",
              spaceBetween: 40,
            },
          }}
        >
          {waypoints.map((w, i) => (
            <SwiperSlide key={i}>
              <a
                className={classNames(
                  "index-bar__item",
                  i === waypointIndex && "index-bar__item--selected",
                  open && "index-bar__open"
                )}
                id={i}
                href={`#${w.value}`}
                onClick={(e) => {
                  setActiveWaypoint(w.value);
                  if (isMobile) {
                    setOpen((current) => !current);
                  }
                }}
              >
                <span className="index-bar__index">{i >= 9 ? i + 1 : `0${i + 1}`}.</span>
                <div className="index-bar__title">{w.label}</div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default IndexBar;
