import { useCallback, useContext, useRef, useState, useId } from "react";
import { Navigate, useLocation, Link, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import UserContext from "../context/user.jsx";
import Icon from "../components/icon.jsx";
import logo from "../images/OneEssilorLuxotticaLogo.svg";
import LanguageContext from "../context/language.jsx";
import useTealiumOnPageLoad from "../utils/tealium.js";
import HomeScreenPrompt from "../components/home-screen-prompt.jsx";
import useRedirectMicrosoft from "../hooks/queries/useRedirectMicrosoft.js";
import { HomeScreenPromptProvider } from "../context/home-screen-prompt.jsx";

const InputField = ({ type, label, name, value, onChange, error, deleteLabel, ...other }) => {
  const ref = useRef();
  const fieldId = useId();
  const [visiblePsw, setVisiblePsw] = useState(false);
  const togglePswVisibility = useCallback(() => setVisiblePsw((value) => !value), []);

  const clearInput = useCallback(
    (e) => {
      const fieldName = e.currentTarget.name?.split("clear_")?.[1];
      onChange({ target: { name: fieldName, value: "" } });
      ref?.current?.focus();
    },
    [onChange]
  );

  return (
    <div className="login__form-element">
      <div
        className={classNames("input", {
          "input--filled": !!value,
          "input--error": error,
        })}
      >
        <label className="input__label" htmlFor={fieldId}>
          {label}
        </label>
        <input
          id={fieldId}
          type={visiblePsw && type === "password" ? "text" : type}
          ref={ref}
          name={name}
          className="input__field"
          value={value}
          onChange={onChange}
          maxLength={80}
          {...other}
        />
        {!!value && (
          <button
            type="button"
            name={`clear_${name}`}
            className="input__icon-wrapper input__icon-wrapper--delete"
            aria-label={deleteLabel}
            tabIndex="-1"
            onClick={clearInput}
            onTouchStart={clearInput}
          >
            <Icon name="delete" className="input__icon-delete" />
          </button>
        )}
        {error && (
          <span className="input__icon-wrapper input__icon-wrapper--error">
            <Icon name="error-sign" />
          </span>
        )}
        {type === "password" && (
          <button
            type="button"
            className="input__icon-wrapper input__icon-wrapper--visibility"
            aria-label="Toggle password visibility"
            tabIndex="-1"
            onClick={togglePswVisibility}
          >
            <Icon name={visiblePsw ? "eye" : "eye-slash"} />
          </button>
        )}
      </div>
      {error && <div className="input__error">{error}</div>}
    </div>
  );
};

const SKIP_PATHS = ["/login", "/error"];

const Login = () => {
  const { login, logged } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const [{ username, password }, setLoginData] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { data: redirectUrl } = useRedirectMicrosoft();
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get("errorMessage");
  useTealiumOnPageLoad({ notLogged: true });

  const onChange = useCallback((e) => {
    setLoginData((value) => ({
      ...value,
      [e.target.name]: e.target.value,
    }));
    setErrors({});
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!username?.length || !password?.length) {
      const emptyFieldError = labels?.NOT_EMPTY_FIELD;
      setErrors({
        username: !username?.length ? emptyFieldError : null,
        password: !password?.length ? emptyFieldError : null,
      });
      return;
    }
    setLoading(true);
    try {
      await login(username, password);
    } catch (err) {
      console.error(err);
      setErrors({
        username: labels?.ACCOUNT_NOT_FOUND,
        password: labels?.ACCOUNT_NOT_FOUND,
      });
    }
    setLoading(false);
  };

  const prevPathname = location.state?.pathname;
  const prevPathSearch = location.state?.search || "";

  // useEffect(() => {
  //   if (prevPathname) {
  //     sessionStorage.setItem(
  //       "pre-login-path",
  //       JSON.stringify({ pathname: prevPathname, search: prevPathSearch })
  //     );
  //   }
  // }, [prevPathname, prevPathSearch]);

  return logged ? (
    <Navigate
      to={prevPathname && !SKIP_PATHS.includes(prevPathname) ? prevPathname + prevPathSearch : "/"}
    />
  ) : (
    <HomeScreenPromptProvider>
      <HomeScreenPrompt />
      <div className="login">
        <div className="wrapper">
          <div className="login__box">
            <img src={logo} alt="OneEssilorLuxottica" role="heading" aria-level="1" />
            <form className="login__form" onSubmit={onSubmit}>
              <InputField
                type="text"
                label={labels?.USERNAME}
                name="username"
                placeholder={`${labels?.USERNAME || ""}`}
                value={username}
                onChange={onChange}
                error={errors.username}
                deleteLabel="Delete username"
                autoComplete="username"
              />
              <InputField
                type="password"
                label={labels?.PASSWORD}
                name="password"
                placeholder={`${labels?.PASSWORD || ""}`}
                value={password}
                onChange={onChange}
                error={errors.password}
                deleteLabel="Delete password"
                autoComplete="current-password"
              />
              <div className="login__link-wrapper">
                <Link to="/forgot-password" tabIndex="-1">
                  {labels?.LOGIN_AND_PASSWORD_SUPPORT || "Login and password support"}
                </Link>
              </div>
              <div className="login__submit-wrapper">
                <button
                  className={classNames("button primary", { active: loading })}
                  disabled={loading}
                >
                  {
                    <>
                      {loading ? <span className="loading-spinner" /> : null}{" "}
                      <span>{labels?.LOGIN}</span>
                    </>
                  }
                </button>
                <div className="login__line">
                  <span>{labels?.LOGIN_OR || "Or"}</span>
                </div>
                <a className="button secondary" href={redirectUrl}>
                  <Icon name="microsoft" />
                  <span> {labels?.LOGIN_WITH_MICROSOFT_365 || "Login with microsoft 365"}</span>
                </a>
                {errorMessage && (
                  <div className="login__ms-error">
                    {labels?.LOGIN_ERROR_WITH_MICROSOFT_365 || "Error with Microsoft 365 login"}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </HomeScreenPromptProvider>
  );
};

export default Login;
