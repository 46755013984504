import { useEffect } from "react";

export default function useScrollPadding() {
  useEffect(() => {
    document.scrollingElement.classList.add("scroll-padding-top");
    return () => {
      document.scrollingElement.classList.remove("scroll-padding-top");
    };
  }, []);
}
