import { useContext, useMemo } from "react";
import LanguageContext from "../context/language";
export const VIDEOPLAYER_WAYPOINT = "news-videoplayer";
export const DOCUMENTS_WAYPOINT = "news-documents";
export const PHOTOGALLERY_WAYPOINT = "news-photogallery";

function useWaypoints(pageContent) {
  const { labels } = useContext(LanguageContext);

  const waypoints = useMemo(() => {
    if (!pageContent?.description) {
      return [];
    }

    const el = document.createElement("div");
    el.innerHTML = pageContent.description;

    let newsWaypoints = [...el.querySelectorAll("a[name]")].map((t) => ({
      label: t.innerText ?? "",
      value: t.name,
    }));

    if (pageContent?.video?.data?.uri) {
      newsWaypoints.push({
        label: labels.VIDEOPLAYER ?? "Video",
        value: VIDEOPLAYER_WAYPOINT,
      });
    }
    if (pageContent?.photoGallery?.some((item) => item?.cropUrlTemplate)) {
      newsWaypoints.push({
        label: labels.PHOTOGALLERY ?? "Photogallery",
        value: PHOTOGALLERY_WAYPOINT,
      });
    }
    if (pageContent?.attachments?.length > 0) {
      newsWaypoints.push({
        label:
          pageContent?.attachments?.length === 1
            ? labels.AVAILABLE_DOCUMENT ?? "Document"
            : labels.AVAILABLE_DOCUMENTS ?? "Documents",
        value: DOCUMENTS_WAYPOINT,
      });
    }

    return newsWaypoints;
  }, [labels, pageContent]);
  return waypoints;
}

export default useWaypoints;
