import classNames from "classnames";
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import UserContext from "../context/user.jsx";
import useClickOut from "../hooks/useClickOut.js";
import Navigation from "./navigation.jsx";
import Icon from "./icon.jsx";
import logo from "../images/OneEssilorLuxotticaLogo.svg";
import useBlockScroll from "../hooks/useBlockScroll.js";
import LanguageContext from "../context/language.jsx";
import LoadingError from "../routes/loading-error.jsx";
import useBreakpoint from "../hooks/useBreakpoint.js";
import HomeScreenPrompt from "./home-screen-prompt.jsx";
import { HomePageLanguageSelect } from "../routes/settings.jsx";

const ShareWidget = lazy(() => import("./share-widget.jsx"));

const UserSideBar = React.forwardRef(({ userProfile, userOpen, close }, ref) => {
  const { logout } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);

  return (
    <div className={classNames("header__user-bar", userOpen && "visible")} ref={ref}>
      <button className="header__user-bar-close-btn" onClick={close} aria-label="Close">
        <Icon name="x-icon" />
      </button>
      <div className="header__user-bar-header">
        {userProfile ? (
          userProfile?.avatarUrl ? (
            <img
              className="header__user-bar-image"
              src={userProfile.avatarUrl}
              alt={`${userProfile.firstName?.[0]?.toUpperCase()}${userProfile.lastName?.[0]?.toUpperCase()}`}
            />
          ) : (
            <span className="header__user-bar-image">
              {userProfile
                ? `${userProfile.firstName?.[0]?.toUpperCase()}${userProfile.lastName?.[0]?.toUpperCase()}`
                : ""}
            </span>
          )
        ) : null}
        {userProfile === null ? (
          <LoadingError />
        ) : userProfile ? (
          <div className="header__user-info">
            <h1 className="header__user-name" translate="no">
              {userProfile.firstName?.toLowerCase()} {userProfile.lastName?.toLowerCase()}
            </h1>
            <div className="header__user-entity">{userProfile.zbusTypeT?.toLowerCase()}</div>
            <div className="header__user-job">{userProfile.jobTitle?.toLowerCase()}</div>
          </div>
        ) : null}
      </div>
      <>
        <div className="header__link-wrapper">
          <Link to="/profile" className="header__link" onClick={close}>
            <Icon name="arrow" />
            {labels?.BOOKMARKS}
          </Link>
          <Link to="/profile/settings" className="header__link" onClick={close}>
            <Icon name="arrow" /> {labels?.MY_LANGUAGE}
          </Link>
          <Link to="/profile/info" className="header__link" onClick={close}>
            <Icon name="arrow" />
            {labels?.MY_INFO}
          </Link>
        </div>
        <Link
          to="/contact-and-support"
          className="header__highlighted header__highlighted--contact-and-support cta-link"
          onClick={close}
        >
          {labels?.CONTACT_AND_SUPPORT}
          <Icon name="info-tooltip" />
        </Link>
        <Link to="/login" className="header__highlighted cta-link" onClick={logout}>
          {labels?.LOGOUT}
          <Icon name="arrow" />
        </Link>
      </>
    </div>
  );
});

const Header = ({ menu, setSearchVisible, setInternalMenuOpen }) => {
  const { userProfile, loadProfile, callApi } = useContext(UserContext);
  const { labels, language, languages, cmsLanguages, cmsLang } = useContext(LanguageContext);
  const [userOpen, setUserOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useBreakpoint(991);
  const [selectedLanguage, setSelectedLanguage] = useState();

  const currentLanguage = useMemo(() => {
    if (languages && language) {
      return languages.find((item) => item.value === language);
    }
  }, [language, languages]);

  useEffect(() => {
    if (cmsLang) {
      setSelectedLanguage(cmsLanguages?.find((lang) => lang.value === cmsLang));
    }
  }, [cmsLang, cmsLanguages]);

  const onUpdate = useCallback(async () => {
    await loadProfile();
    //TODO handle reload profile error?
  }, [loadProfile]);

  const updateLanguage = async (e) => {
    setSelectedLanguage(e);

    if (e?.profile && e?.profile !== currentLanguage?.value) {
      try {
        await callApi(`/profile?language=${e?.profile}`, {
          method: "POST",
        });
        await onUpdate();
      } catch (err) {
        console.error("Error updating language: ", err);
        setSelectedLanguage(currentLanguage?.value);
      }
    }
  };

  const [userRef] = useClickOut(userOpen, setUserOpen);
  const [menuRef, btnRef] = useClickOut(menuOpen, setMenuOpen);

  const closeAll = useCallback(() => {
    setMenuOpen(false);
    setUserOpen(false);
  }, []);

  useBlockScroll(menuOpen || userOpen);

  return (
    <header
      className={classNames(
        "header",
        userOpen && "header--user-open",
        menuOpen && "header--menu-open"
      )}
    >
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
      ></Helmet>
      <HomeScreenPrompt />
      <div className="header__top">
        <div className="wrapper">
          <div className="header__row">
            <Link to="/" className="header__logo-cta">
              <img className="header__logo" src={logo} alt="OneEssilorLuxottica" />
            </Link>
            <div className="header__content">
              <Suspense>
                <ShareWidget />
              </Suspense>
              <div
                className={classNames(
                  "header__language-wrapper",
                  !selectedLanguage && "loading-skeleton"
                )}
              >
                <HomePageLanguageSelect
                  options={cmsLanguages}
                  placeholder={currentLanguage?.label}
                  label="Language"
                  selectedLanguage={selectedLanguage}
                  id="language"
                  updateLanguage={updateLanguage}
                />
              </div>
              <div className="header__search-wrapper">
                <button
                  className="header__search"
                  onClick={() => setSearchVisible(true)}
                  aria-label="Search"
                >
                  <Icon name="search" />
                </button>
              </div>
              <div
                className={classNames(
                  "header__user-wrapper",
                  userOpen && "header__user-wrapper--active"
                )}
              >
                <button
                  className={classNames(
                    "header__user",
                    !userProfile?.avatarUrl && "header__user--placeholder"
                  )}
                  onClick={() => {
                    setUserOpen(true);
                    setMenuOpen(false);
                  }}
                  aria-label={`${userProfile?.firstName?.[0]}.${userProfile?.lastName?.[0]}`}
                >
                  {userProfile &&
                    (userProfile.avatarUrl ? (
                      <img
                        src={userProfile.avatarUrl}
                        alt={`${userProfile?.firstName?.[0]}.${userProfile?.lastName?.[0]}`}
                        className="header__user-image"
                      />
                    ) : (
                      `${userProfile?.firstName?.[0]}${userProfile?.lastName?.[0]}`
                    ))}
                </button>
              </div>
              <button
                className="header__hamburger"
                ref={btnRef}
                onClick={() => {
                  setMenuOpen((current) => !current);
                  setUserOpen(false);
                  setInternalMenuOpen(false);
                  if (!isMobile) {
                    window.scrollTo(0, 0);
                  }
                }}
                disabled={!menu}
                aria-label="Hamburger menu"
              >
                <span className="header__title-menu">{labels?.MENU_HOMEPAGE || "Menu"}</span>
                {menuOpen ? <Icon name="x-icon" /> : <Icon name="hamburger" />}
              </button>
              <UserSideBar
                userProfile={userProfile}
                userOpen={userOpen}
                close={closeAll}
                ref={userRef}
              />
            </div>
          </div>
        </div>
      </div>
      {menuOpen && (
        <Navigation
          menu={menu}
          menuOpen={menuOpen}
          closeMenu={closeAll}
          ref={menuRef}
          selectedLanguage={selectedLanguage}
          updateLanguage={updateLanguage}
          options={cmsLanguages}
        />
      )}
    </header>
  );
};

export default Header;
