import { useContext, Suspense, lazy } from "react";
import classNames from "classnames";
import parse, { domToReact } from "html-react-parser";
import { Link } from "react-router-dom";
import UserContext from "../context/user";
import Image from "./image";
import { useTranslation } from "./translate";
import VideoPlayer from "./video-player";
import { Waypoint } from "react-waypoint";
import useHeaderBreakpointOffsets from "../hooks/useHeaderBreakpointOffsets";

const EventBookingContentWidget = lazy(() =>
  import("./event-booking-widget").then((module) => ({ default: module.EventBookingContentWidget }))
);
const WidgetContentSurvey = lazy(() =>
  import("./widget-survey").then((module) => ({ default: module.WidgetContentSurvey }))
);

const ParsedImage = ({ elem, crop }) => {
  const className = elem.attribs?.class;
  const float = className?.includes("float--") && className.split("--")?.[1];
  return elem.attribs?.["data-useoriginalimage"] === "true" ? (
    <img src={elem.attribs?.["data-uri"]} alt={elem.attribs?.alt || ""} className={className} />
  ) : (
    <Image
      cropUrlTemplate={elem.attribs?.["data-uritemplate"]}
      crops={[
        {
          crop: crop,
          sizes: [
            {
              breakpoint: 992,
              maxWidth: "100vw",
            },
            {
              breakpoint: 1230,
              maxWidth: ["left", "right"].includes(float) ? "380px" : "70vw",
            },
            {
              maxWidth: ["left", "right"].includes(float) ? "520px" : "720px",
            },
          ],
        },
      ]}
      className={className}
      alt={elem.attribs?.alt || ""}
    />
  );
};

const Content = ({ news, className, onEnter }) => {
  const { config } = useContext(UserContext);
  const offsets = useHeaderBreakpointOffsets();

  const quoteParseOpt = {
    replace: (elem) => {
      if (elem && elem.type === "tag" && elem.name === "img") {
        return <ParsedImage elem={elem} crop="portrait_ratio1x1" />;
      }
    },
  };

  const parseOpt = {
    replace: (elem) => {
      if (elem) {
        if (
          elem.type === "tag" &&
          elem.name === "p" &&
          elem.children?.some((child) => child?.name === "survey" || child?.name === "event")
        ) {
          return <div>{domToReact(elem.children, parseOpt)}</div>;
        }
        if (
          elem.type === "tag" &&
          elem.name === "blockquote" &&
          elem.children?.some(
            (child) =>
              child?.attribs?.class === "quotation--body" ||
              child?.attribs?.class === "quotation--footer"
          )
        ) {
          const img = elem.children
            ?.find((child) => child.attribs.class === "quotation--body")
            ?.children?.find((child) => child.name === "img");
          let body = elem.children?.find((child) => child.attribs.class === "quotation--body");
          if (body) {
            body.children = body.children?.filter((child) => child.name !== "img");
          }
          const footer = elem.children?.find(
            (child) => child.attribs.class === "quotation--footer"
          );
          return (
            <blockquote className="quotation">
              {img ? domToReact([img], quoteParseOpt) : null}
              <div className="quotation-text">
                {body ? domToReact([body], parseOpt) : null}
                {footer ? domToReact([footer], parseOpt) : null}
              </div>
            </blockquote>
          );
        }
        if (elem.type === "tag" && elem.name === "survey") {
          const surveyMasterId = elem.attribs?.masterid;
          return config.hideSurveys ? (
            <span>{domToReact(elem.children, parseOpt)}</span>
          ) : (
            <Suspense>
              <WidgetContentSurvey surveyMasterId={surveyMasterId} />
            </Suspense>
          );
        }
        if (elem.type === "tag" && elem.name === "event") {
          const eventMasterId = elem.attribs?.masterid;
          return config.hideEvents ? (
            <span>{domToReact(elem.children, parseOpt)}</span>
          ) : (
            <Suspense>
              <EventBookingContentWidget eventMasterId={eventMasterId} />
            </Suspense>
          );
        }
        if (elem.type === "tag" && elem.name === "p" && elem.children?.[0]?.name === "img") {
          const className = elem.children?.[0]?.attribs?.class;
          const float = className?.includes("float--") && className.split("--")?.[1];

          if (["left", "right"].includes(float)) {
            return (
              <p className={classNames("floating", elem.attribs.class)}>
                {domToReact(elem.children, parseOpt)}
              </p>
            );
          }
        }
        if (elem.type === "tag" && elem.name === "img") {
          return <ParsedImage elem={elem} crop="landscape_ratio16x9" />;
        }
        if (elem.type === "tag" && elem.name === "a") {
          // gestione delle ancore per le news
          if (elem.attribs?.name && elem.attribs?.["data-show"]) {
            return (
              <Waypoint
                onEnter={() => {
                  onEnter(elem.attribs?.name);
                }}
                // misure euristiche dell'ingombro dell'header
                topOffset={offsets.top}
                bottomOffset={offsets.bottom}
              >
                <a
                  name={elem.attribs?.name}
                  href={`#${elem.attribs?.name}`}
                  data-show={elem.attribs?.["data-show"]}
                >
                  {domToReact(elem.children)}
                </a>
              </Waypoint>
            );
          }

          if (elem.attribs?.["data-href"]?.startsWith("coremedia:///cap/content/")) {
            const contentId = elem.attribs["data-href"].slice(
              elem.attribs["data-href"].lastIndexOf("/") + 1
            );
            if (contentId) {
              return <Link to={`/internal-link/${contentId}`}>{domToReact(elem.children)}</Link>;
            }
          } else
            return (
              <a
                href={elem.attribs?.href}
                target={elem.attribs?.["data-show"] === "new" ? "_blank" : "_self"}
              >
                {domToReact(elem.children)}
              </a>
            );
        }
        if (elem.type === "tag" && elem.name === "video") {
          const videoUrl = elem?.attribs?.["video-url"];
          const videoBlob = elem?.attribs?.["video-blob"];
          const videoCoverImage = elem?.attribs?.["video-cover"];
          return videoUrl ? (
            <VideoPlayer video={{ dataUrl: videoUrl }} />
          ) : (
            <VideoPlayer
              video={{ data: { uri: videoBlob }, picture: { cropUrlTemplate: videoCoverImage } }}
            />
          );
        }
      }
    },
  };

  const result = useTranslation(news?.description);

  return result ? (
    <div className={classNames("content loading-highlighted", className)}>
      {parse(result, parseOpt)}
    </div>
  ) : null;
};

export default Content;
