import { useCallback, useContext, useEffect, useMemo, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import Home from "./home/home.jsx";
import Page from "./page.jsx";
import NoPage from "./no-page.jsx";
import LoadingError from "./loading-error.jsx";
import Profile from "./profile.jsx";
import Header from "../components/header.jsx";
import Footer from "../components/footer.jsx";
import NewsDetail from "./news-detail.jsx";
import NoteDetail from "./note-detail.jsx";
import LanguageContext from "../context/language.jsx";
import TranslationButton from "../components/translation-button.jsx";
import AppsLinks from "./apps-links/apps-links.jsx";
import WhoIsWho from "./who-is-who/who-is-who.jsx";
import News from "./news.jsx";
import Notes from "./notes.jsx";
import Error from "../components/error.jsx";
import { TranslateProvider } from "../components/translate.jsx";
import Modal from "../components/modal";
import Icon from "../components/icon.jsx";
import CookiePolicy from "./cookie-policy.jsx";
import Hubs from "./hubs.jsx";
import MyNews from "./my-news.jsx";
import UserContext from "../context/user.jsx";
import getPageName from "../utils/getPageName.js";
import Communities from "./communities/communities.jsx";
import Resources from "./resources/resources.jsx";
import ResourceDetail from "./resources/resource-detail.jsx";
import InternalLink from "./internal-link.jsx";
import LandingPage from "./landing-page.jsx";
import SupportPage from "../components/support-page.jsx";
import useMenu from "../hooks/queries/useMenu.js";
import LinkPage from "./link-page.jsx";
import PolicyDetail from "./policy/policy-detail.jsx";
import {
  HomeScreenPromptContext,
  HomeScreenPromptProvider,
} from "../context/home-screen-prompt.jsx";

const Research = lazy(() => import("../components/research.jsx"));
const Hub = lazy(() => import("./hub.jsx"));
const Community = lazy(() => import("./community.jsx"));

// const SKIP_PATHS = ["/login", "/error"];

const PageTemplate = ({ menu, page, internalMenuOpen, setInternalMenuOpen }) => {
  return (
    {
      coldpage: (
        <Page
          page={page}
          key={page.link}
          internalMenuOpen={internalMenuOpen}
          setInternalMenuOpen={setInternalMenuOpen}
        />
      ),
      "app-and-links": <AppsLinks page={page} key={page.link} />,
      "who-is-who": <WhoIsWho page={page} key={page.link} />,
      "news-search-page": <News key={page.link} page={page} />,
      "organizational-notes-search-page": <Notes key={page.link} page={page} />,
      "press-release-search-page": <Notes key={page.link} press={true} page={page} />,
      "my-news-page": <MyNews key={page.link} page={page} />,
      "hub-search-page": <Hubs key={page.link} page={page} />,
      "communities-page": <Communities page={page} />,
      "resources-search-page": <Resources key={page.link} page={page} />,
      linkpage: (
        <LinkPage
          key={page.link}
          page={page}
          menu={menu}
          internalMenuOpen={internalMenuOpen}
          setInternalMenuOpen={setInternalMenuOpen}
        />
      ),
      landingpage: <LandingPage key={page.link} page={page} />,
    }[page.pageTemplate] || <Error msg={`Missing template ${page.pageTemplate}`} key={page.link} />
  );
};

const AppRoutes = () => {
  const { isPreview, showPreview, logged, isSAMLLoggingOut } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const location = useLocation();
  const { showHomeScreenPrompt } = useContext(HomeScreenPromptContext);

  const pathname = location.pathname;
  const [internalMenuOpen, setInternalMenuOpen] = useState();

  const { data: resultMenu } = useMenu({ path: "top-menu" });
  const { data: resultFooterMenu } = useMenu({ path: "footer" });

  const menu = useMemo(() => {
    const firstLevelPages = resultMenu?.children || [];
    if (resultMenu?.children) {
      return firstLevelPages.map((page) => ({
        label: getPageName(page, labels),
        link: `/${page.segment}`,
        pageTemplate: page.subjectTaxonomy?.[0]?.externalReference,
        segment: page?.segment,
        origin: "top-menu",
        children: page.children.map((item) => {
          const subPage = {
            label: getPageName(item, labels),
            link: `/${page.segment}/${item.segment}`,
            origin: "top-menu",
            pageTemplate: item.subjectTaxonomy?.[0]?.externalReference,
            segment: item?.segment,
          };
          const rawChildren = item.children.map((child) => ({
            label: getPageName(child, labels),
            link: `/${page.segment}/${item.segment}/${child.segment}`,
            origin: "top-menu",
            pageTemplate: child.subjectTaxonomy?.[0]?.externalReference,
            segment: child?.segment,
          }));
          const siblings = rawChildren?.length ? [subPage].concat(rawChildren) : null;
          return {
            ...subPage,
            children: rawChildren.map((child) => ({
              ...child,
              siblings,
            })),
            siblings,
          };
        }),
      }));
    } else {
      return null;
    }
  }, [resultMenu, labels]);

  const footerMenu = useMemo(() => {
    const footerMenu = resultFooterMenu?.children || [];
    if (resultFooterMenu?.children) {
      return footerMenu
        ?.filter((page) => !!page.subjectTaxonomy?.[0]?.externalReference)
        ?.map((page) => ({
          label: getPageName(page, labels),
          link: `/${page.segment}`,
          origin: "footer",
          pageTemplate: page.subjectTaxonomy?.[0]?.externalReference,
          segment: page?.segment,
        }));
    } else {
      return null;
    }
  }, [resultFooterMenu, labels]);

  const supportPage = useMemo(() => {
    const supportPageFromFooter = (resultFooterMenu?.children || [])?.find(
      (page) => page.segment === "contact-and-support"
    );

    return (
      supportPageFromFooter && {
        ...supportPageFromFooter,
        label: getPageName(supportPageFromFooter, labels),
        origin: "footer",
      }
    );
  }, [resultFooterMenu, labels]);

  const allMenu = useMemo(() => {
    return (menu || []).concat(footerMenu || []);
  }, [menu, footerMenu]);

  const routes = useMemo(() => {
    const menuRoutes = menu
      ? menu
          .map((item) =>
            [item].concat(
              item.pageTemplate === "linkpage"
                ? []
                : item.children.map((child) => [child].concat(child.children))
            )
          )
          .flat(2)
          .filter((item) => item.link)
      : null;
    const footerMenuRoutes = footerMenu;
    return footerMenuRoutes
      ? menuRoutes
        ? menuRoutes.concat(footerMenuRoutes)
        : null
      : menuRoutes;
  }, [menu, footerMenu]);

  const [searchVisible, setSearchVisible] = useState(false);

  const homeLinks = useCallback(
    (tab, section) => {
      const link =
        tab === "policies"
          ? routes
              ?.find(
                (route) =>
                  route.pageTemplate === "linkpage" &&
                  route.children.some(
                    (child) => child.pageTemplate === "policies-and-procedures-search-page"
                  )
              )
              ?.children?.find(
                (child) => child.pageTemplate === "policies-and-procedures-search-page"
              )?.link
          : routes?.find(
              (route) =>
                route.pageTemplate ===
                {
                  news: "news-search-page",
                  notes: "organizational-notes-search-page",
                  press: "press-release-search-page",
                  "my-news": "my-news-page",
                  appsLinks: "app-and-links",
                  contacts: "who-is-who",
                  communities: "communities-page",
                  hubs: "hub-search-page",
                  resources: "resources-search-page",
                }[tab]
            )?.link || "";
      return link && section ? `${link}?section=${section}` : link;
    },
    [routes]
  );

  useEffect(() => setSearchVisible(false), [location]);

  return logged ? (
    <>
      <Header
        menu={menu}
        setSearchVisible={setSearchVisible}
        setInternalMenuOpen={setInternalMenuOpen}
      />
      {isPreview && (
        <div
          className={classNames(
            "preview-bar",
            showHomeScreenPrompt && "preview-bar--home-screen-prompt"
          )}
        >
          {labels?.CLOSE_PREVIEW_MODE}
          <button className="preview-bar__close" onClick={() => showPreview(false)}>
            <Icon name="x-icon" />
          </button>
        </div>
      )}
      <TranslateProvider>
        <div
          className={classNames(
            "wrapper page-content",
            isPreview && "page-content--preview",
            showHomeScreenPrompt && "page-content--home-screen-prompt"
          )}
        >
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  newsLink={homeLinks("news")}
                  organizationalNotesLink={homeLinks("notes")}
                  pressLink={homeLinks("press")}
                  myNewsLink={homeLinks("my-news")}
                  appsLinksLink={homeLinks("appsLinks")}
                  communitiesLink={homeLinks("communities")}
                  hubsLink={homeLinks("hubs")}
                  menu={allMenu}
                />
              }
            />
            {routes?.map((item) => (
              <Route
                path={item.pageTemplate === "linkpage" ? `${item.link}/*` : item.link}
                element={
                  <PageTemplate
                    page={item}
                    internalMenuOpen={internalMenuOpen}
                    setInternalMenuOpen={setInternalMenuOpen}
                    menu={menu}
                  />
                }
                key={item.link}
              />
            ))}
            {routes && (
              <>
                <Route
                  path="/profile/*"
                  element={
                    <Profile
                      backLinks={{
                        appsLinks: homeLinks("appsLinks"),
                        contacts: homeLinks("contacts"),
                        resources: homeLinks("resources"),
                        policies: homeLinks("policies"),
                        communities: homeLinks("communities"),
                        hubs: homeLinks("hubs"),
                      }}
                    />
                  }
                />
                <Route
                  path="/news/:newsId"
                  element={
                    <NewsDetail
                      backLink={{ link: homeLinks("news"), label: labels?.GO_TO_NEWS_LIST }}
                    />
                  }
                />
                <Route
                  path="/notes/:noteId"
                  element={<NoteDetail backLink={homeLinks("notes")} menu={menu} />}
                />
                <Route
                  path="/press/:noteId"
                  element={<NoteDetail backLink={homeLinks("press")} press={true} menu={menu} />}
                />
                <Route
                  path="/cookie-policy"
                  element={
                    <CookiePolicy logged={logged} menu={menu} setSearchVisible={setSearchVisible} />
                  }
                />
                <Route path="/contact-and-support" element={<SupportPage page={supportPage} />} />
                <Route
                  path="/business-hubs/:hubName/*"
                  element={
                    <Suspense fallback={<div className="loading-spinner loading-spinner--hub" />}>
                      <Hub
                        internalMenuOpen={internalMenuOpen}
                        setInternalMenuOpen={setInternalMenuOpen}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/regional-hubs/:hubName/*"
                  element={
                    <Suspense fallback={<div className="loading-spinner loading-spinner--hub" />}>
                      <Hub
                        internalMenuOpen={internalMenuOpen}
                        setInternalMenuOpen={setInternalMenuOpen}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/communities/:communityId"
                  element={
                    <Suspense fallback={<div className="loading-spinner loading-spinner--hub" />}>
                      <Community key={pathname} backLink={homeLinks("communities", "all")} />
                    </Suspense>
                  }
                />
                <Route
                  path="/communities/:communityId/:postId"
                  element={
                    <Suspense fallback={<div className="loading-spinner loading-spinner--hub" />}>
                      <Community key={pathname} backLink={homeLinks("communities", "all")} />
                    </Suspense>
                  }
                />
                <Route
                  path="/resource/:resourceId"
                  element={
                    <ResourceDetail
                      backLink={{
                        link: homeLinks("resources"),
                        label: labels?.GO_TO_RESOURCES_LIST || "Go to Resources list",
                      }}
                    />
                  }
                />
                <Route
                  path="/policies-and-procedures-detail/:policyId"
                  element={
                    <PolicyDetail
                      backLink={{
                        link: homeLinks("policies"),
                        label:
                          labels?.GO_TO_POLICIES_AND_PROCEDURES_LIST ||
                          "Go to Policies & Procedures list",
                      }}
                    />
                  }
                />
                <Route path="/internal-link/:contentId" element={<InternalLink />} />
                <Route path="*" element={<NoPage />} />
              </>
            )}
            <Route path="/error" element={<LoadingError />} />
          </Routes>
          <TranslationButton />
          {searchVisible && (
            <Modal
              show={searchVisible}
              setShow={setSearchVisible}
              className="intranet-modal intranet-modal--full intranet-modal--research"
            >
              <Suspense fallback={<div className="loading-spinner" />}>
                <Research menu={allMenu} />
              </Suspense>
            </Modal>
          )}
          {isSAMLLoggingOut && (
            <Modal
              show={isSAMLLoggingOut}
              readOnly={true}
              className="intranet-modal intranet-modal--logout"
            >
              <div className="intranet-modal__body wrapper">
                <span>{labels?.LOGGING_OUT || "Logging out"}</span>
                <span className="loading-spinner" />
              </div>
            </Modal>
          )}
        </div>
      </TranslateProvider>
      <Footer footerMenu={footerMenu} supportPage={supportPage} />
    </>
  ) : (
    <Navigate to="/login" state={{ pathname: location.pathname, search: location.search }} />
  );
};

const Wrapper = (props) => (
  <HomeScreenPromptProvider>
    <AppRoutes {...props} />
  </HomeScreenPromptProvider>
);

export default Wrapper;
