import { useContext, useEffect, useRef, useState, useMemo, Suspense, lazy } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VideoPlayer from "../components/video-player";
import Photogallery from "../components/photogallery";
import UserContext from "../context/user";
import RelatedNews from "../components/related-news";
import Interaction from "../components/interactions";
import LanguageContext from "../context/language";
import Content from "../components/content";
import Icon from "../components/icon";
import imagePh from "../images/placeholder-image.png";
import useTealiumOnPageLoad from "../utils/tealium";
import Translate from "../components/translate";
import LoadingError from "./loading-error";
import NewsCard from "../components/news-card";
import Image from "../components/image";
import NewsComments from "../components/news-comments";
import Documents from "../components/documents";
import useScrollToLocation from "../hooks/useScrollToLocation";
import useScrollPadding from "../hooks/useScrollPadding";
import { LightModeBanner } from "../components/toggle-light-mode";
import IndexBar from "../components/index-bar";
import { Waypoint } from "react-waypoint";
import useHeaderBreakpointOffsets from "../hooks/useHeaderBreakpointOffsets";
import useBreakpoint from "../hooks/useBreakpoint";
import useWaypoints, {
  DOCUMENTS_WAYPOINT,
  PHOTOGALLERY_WAYPOINT,
  VIDEOPLAYER_WAYPOINT,
} from "../hooks/useWaypoints";

const EventBookingWidget = lazy(() => import("../components/event-booking-widget"));
const WidgetSurvey = lazy(() => import("../components/widget-survey"));

dayjs.extend(customParseFormat);

const NewsDetailInfoBox = ({
  isSeeMore,
  news,
  backLink,
  setIsSeeMore,
  newsId,
  config,
  eventList,
  surveyList,
  setLoadedSurvey,
}) => {
  const { labels } = useContext(LanguageContext);

  return (
    <div className="news-detail__info-box loading-highlighted">
      <div
        className={classNames(
          "news-detail__tag-wrapper",
          isSeeMore && "news-detail__tag-wrapper--see-more"
        )}
      >
        <div>
          {news?.categories?.length > 0 && (
            <div className="news-detail__info">
              <div className="news-detail__tag-label">{labels?.CATEGORYFACET}</div>
              <div className="news-detail__tag-value-container">
                {news?.categories?.map((cat, i) => (
                  <Link
                    to={`${backLink?.link}?category=${cat}`}
                    key={i}
                    className="news-detail__tag-value"
                  >
                    {labels?.[cat.toUpperCase()] || cat}
                  </Link>
                ))}
                {!isSeeMore && (
                  <button
                    className="news-detail__tag-value news-detail__tag-value--see-more"
                    onClick={() => setIsSeeMore(true)}
                  >
                    ...
                  </button>
                )}
              </div>
            </div>
          )}
          {news?.entity && (
            <div className="news-detail__info">
              <div className="news-detail__tag-label">{labels?.ENTITYFACET}</div>
              <div className="news-detail__tag-value-container">
                <Link
                  to={`${backLink?.link}?entity=${news.entity}`}
                  className="news-detail__tag-value"
                >
                  {labels?.[news.entity.toUpperCase()] || news.entity}
                </Link>
              </div>
            </div>
          )}
          {news?.newsType?.name && (
            <div className="news-detail__info">
              <div className="news-detail__tag-label">{labels?.NEWSTYPEFACET}</div>
              <div className="news-detail__tag-value-container">
                <Link
                  to={`${backLink?.link}?newsType=${news.newsType.externalReference}`}
                  className="news-detail__tag-value"
                >
                  {labels?.[news.newsType.externalReference.toUpperCase()] || news.newsType.name}
                </Link>
              </div>
            </div>
          )}
          {news?.functions?.length > 0 && (
            <div className="news-detail__info">
              <div className="news-detail__tag-label">{labels?.FUNCTIONFACET}</div>
              <div className="news-detail__tag-value-container">
                {news.functions?.map((fun, i) => (
                  <Link
                    to={`${backLink?.link}?function=${fun}`}
                    key={i}
                    className="news-detail__tag-value"
                  >
                    {labels?.[fun.toUpperCase()] || fun}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
        <button
          className="news-detail__see-more"
          onClick={() => setIsSeeMore(!isSeeMore)}
          aria-label={isSeeMore ? "See less" : "See more"}
        >
          {isSeeMore ? (
            <Icon name="minus" className="icon--circled" />
          ) : (
            <Icon name="plus" className="icon--circled" />
          )}
        </button>
      </div>
      <Interaction
        likesCounter={news?.likesCount}
        commentsCounter={news?.commentsCount}
        isLiked={news?.isLiked}
        newsId={newsId}
        goToComments={true}
        showLabel={true}
      />
      {((!config.hideEvents && eventList?.length > 0) ||
        (!config.hideSurveys && surveyList?.length > 0)) && (
        <div className="news-detail__survey-wrapper">
          {!config.hideEvents && (
            <Suspense>
              {eventList?.map((event) => (
                <EventBookingWidget
                  key={event?.id}
                  event={{
                    id: event?.linkURL,
                    imageUrl: event?.coverImage?.cropUrlTemplate,
                    categories: event?.intro,
                    intro: event?.description,
                  }}
                  small={true}
                />
              ))}
            </Suspense>
          )}
          {!config.hideSurveys && (
            <Suspense>
              {surveyList?.map((survey) => (
                <WidgetSurvey
                  key={survey?.id}
                  survey={{
                    id: survey?.linkURL,
                    imageUrl: survey?.coverImage?.cropUrlTemplate,
                    categories: survey?.intro,
                    intro: survey?.description,
                    linkLabel: survey?.linkLabel,
                  }}
                  small={true}
                  setLoadedSurvey={setLoadedSurvey}
                />
              ))}
            </Suspense>
          )}
        </div>
      )}
    </div>
  );
};

const NewsDetail = ({ backLink }) => {
  const { isPreview, userProfile, callApi, config, lightMode, setLightMode } =
    useContext(UserContext);
  const { labels, cmsLang } = useContext(LanguageContext);
  const { newsId } = useParams();
  const [news, setNews] = useState({});
  const [isSeeMore, setIsSeeMore] = useState();
  const [error, setError] = useState();
  const isMobile = useBreakpoint(767);

  useTealiumOnPageLoad({ pageSection1: "news-detail", pageSection2: news?.title }, !!news?.title);

  const loadedNews = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [loadedSurvey, setLoadedSurvey] = useState(false);
  useScrollToLocation(!!loadedNews.current && loadedSurvey);

  useEffect(() => {
    if (cmsLang && userProfile && loadedNews.current !== cmsLang) {
      loadedNews.current = cmsLang;
      const loadNews = async () => {
        try {
          const result = await callApi(`/resource/news/${newsId}?locale=${cmsLang}`);
          if (result) {
            const extractedResult =
              isPreview && result?.derivedContents?.length ? result.derivedContents[0] : result;
            setNews({
              masterID: extractedResult?.masterID,
              title: extractedResult?.title,
              customPublicationDate: extractedResult?.customPublicationDate,
              newsType: extractedResult?.newsType,
              image: {
                uri: extractedResult?.coverImage?.data?.uri,
                alt: extractedResult?.coverImage?.name,
                visible: extractedResult?.coverImage?.subjectTaxonomy?.every(
                  (elem) => elem?.externalReference !== "thumbnail-only"
                ),
              },
              coverImage: extractedResult?.coverImage,
              categories: extractedResult?.categoryString,
              entity: extractedResult?.entity,
              functions: extractedResult?.functionString,
              region: extractedResult?.regionString,
              intro: extractedResult?.intro,
              description: extractedResult?.description,
              video: extractedResult?.video,
              photoGallery: extractedResult?.photoGallery,
              attachments: extractedResult?.attachments,
              related: extractedResult?.related?.filter(
                (item) =>
                  item?.newsType?.externalReference &&
                  (["corporate-news", "top-leaders", "hub-news"].includes(
                    item.newsType.externalReference
                  ) ||
                    (item.newsType.externalReference === "hr-content" &&
                      (item?.businessHub?.[0]?.externalReference ||
                        item?.regionHub?.[0]?.externalReference)))
              ),
              related2: extractedResult?.related2,
              likesCount: extractedResult?.likesCount,
              commentsCount: extractedResult?.commentsCount,
              isLiked: extractedResult?.liked,
              link: {
                url: extractedResult?.linkURL,
                label: extractedResult?.linkLabel,
                target: extractedResult?.linkTarget,
              },
              hub: {
                type: extractedResult?.businessHub?.[0]?.externalReference
                  ? "business-hubs"
                  : "regional-hubs",
                name:
                  extractedResult?.businessHub?.[0]?.externalReference ||
                  extractedResult?.regionHub?.[0]?.externalReference,
              },
            });
            setLoadedSurvey(
              extractedResult?.related2?.filter(
                (item) => item.newsType?.externalReference === "survey" && !!item?.linkURL
              )?.length === 0
            );
          }
        } catch (err) {
          console.error("Error loading news", err);
          setError(true);
        }
        loadedNews.current = false;
      };
      loadNews();
    }
  }, [userProfile, callApi, newsId, cmsLang, isPreview]);

  const eventList = useMemo(
    () =>
      news?.related2?.filter(
        (item) => item.newsType?.externalReference === "event" && !!item?.linkURL
      ),
    [news]
  );

  const surveyList = useMemo(
    () =>
      news?.related2?.filter(
        (item) => item.newsType?.externalReference === "survey" && !!item?.linkURL
      ),
    [news]
  );

  const waypoints = useWaypoints(news);

  const [waypoint, setWaypoint] = useState("");

  const offsets = useHeaderBreakpointOffsets();
  useScrollPadding();

  return error ? (
    <LoadingError />
  ) : (
    <div className="news-detail">
      <LightModeBanner
        status={lightMode}
        setStatus={setLightMode}
        children={
          state?.navBack ? (
            <button className="return-bar__btn" onClick={() => navigate(-1)}>
              <Icon name="chevron" />
              <span>{labels?.GO_TO_NEWS_LIST}</span>
            </button>
          ) : (
            <Link className="return-bar__btn" to={backLink?.link}>
              <Icon name="chevron" />
              <span>{backLink?.label}</span>
            </Link>
          )
        }
      />

      {isPreview && news?.title && (
        <div className="news-detail__card-wrapper">
          <NewsCard news={news} />
        </div>
      )}
      <article role="region" aria-label="article" className="news-detail__light-mode-container">
        {/* anche se è vuoto serve a non evidenziare nessun waypoint quando la pagina non ha ancora scrollato*/}
        <Waypoint
          onEnter={() => {
            setWaypoint("");
          }}
        >
          <div></div>
        </Waypoint>
        <div
          className={classNames(
            "news-detail__box",
            Object.keys(news).length === 0 && "loading-skeleton",
            news?.image?.uri && news?.image?.visible && "news-detail__box--img"
          )}
        >
          <div className="news-detail__image">
            {news?.image?.uri && news?.image?.visible ? (
              <Image
                cropUrlTemplate={news?.coverImage?.cropUrlTemplate}
                crops={[
                  {
                    crop: "landscape_ratio16x9",
                    sizes: [
                      {
                        maxWidth: "100vw",
                      },
                    ],
                  },
                ]}
                className="news-detail__image-elem"
                alt={news?.image?.alt || news?.title}
                loading="eager"
              />
            ) : Object.keys(news).length === 0 ? (
              <img className="news-detail__image-elem" src={imagePh} alt="" />
            ) : (
              <div className="news-detail__image-elem loading-highlighted"></div>
            )}
          </div>
          <div
            className={classNames(
              "news-detail__header wrapper-small",
              Object.keys(news).length === 0 && "loading-skeleton"
            )}
          >
            <h1 className="heading-small loading-highlighted">
              <Translate text={news?.title} />
            </h1>
            <div className="news-detail__date loading-highlighted">
              {news?.customPublicationDate &&
                dayjs(news.customPublicationDate, "YYYY-MM-DDThh:mm:ssZ[GMT]")
                  .locale(cmsLang)
                  .format("LL")}
            </div>
          </div>
        </div>

        {isMobile && (
          <div className="news-detail__light-mode-container">
            <NewsDetailInfoBox
              backLink={backLink}
              config={config}
              eventList={eventList}
              isSeeMore={isSeeMore}
              news={news}
              newsId={newsId}
              setIsSeeMore={setIsSeeMore}
              setLoadedSurvey={setLoadedSurvey}
              surveyList={surveyList}
            />
          </div>
        )}
        <IndexBar waypoints={waypoints} activeWaypoint={waypoint} setActiveWaypoint={setWaypoint} />
        <div className="news-detail__light-mode-container">
          <div
            className={classNames(
              "news-detail__content wrapper-medium",
              Object.keys(news).length === 0 && "loading-skeleton"
            )}
          >
            {!isMobile && (
              <NewsDetailInfoBox
                backLink={backLink}
                config={config}
                eventList={eventList}
                isSeeMore={isSeeMore}
                news={news}
                newsId={newsId}
                setIsSeeMore={setIsSeeMore}
                setLoadedSurvey={setLoadedSurvey}
                surveyList={surveyList}
              />
            )}
            <div
              className={classNames(
                "news-detail__text text",
                Object.keys(news).length === 0 && "loading-highlighted"
              )}
            >
              <Translate className="text__subtitle" html={news?.intro} />
              <Content news={news} onEnter={(waypointName) => setWaypoint(waypointName)} />
            </div>
          </div>
        </div>
        {news?.video?.data?.uri && (
          <Waypoint
            onEnter={() => {
              setWaypoint(VIDEOPLAYER_WAYPOINT);
            }}
            topOffset={offsets.top}
            bottomOffset={offsets.bottom}
          >
            <div id={VIDEOPLAYER_WAYPOINT}>
              <VideoPlayer video={news.video} />
            </div>
          </Waypoint>
        )}
        {news?.photoGallery?.filter((item) => item?.cropUrlTemplate)?.length > 0 && (
          <Waypoint
            onEnter={() => {
              setWaypoint(PHOTOGALLERY_WAYPOINT);
            }}
            topOffset={offsets.top}
            bottomOffset={offsets.bottom}
          >
            <div id={PHOTOGALLERY_WAYPOINT}>
              <Photogallery
                photogallery={news.photoGallery.filter((item) => item?.cropUrlTemplate)}
              />
            </div>
          </Waypoint>
        )}
        {news?.attachments?.length > 0 && (
          <Waypoint
            onEnter={() => {
              setWaypoint(DOCUMENTS_WAYPOINT);
            }}
            topOffset={offsets.top}
            bottomOffset={offsets.bottom}
          >
            <div id={DOCUMENTS_WAYPOINT}>
              <Documents resourceId={news.id} documents={news.attachments} />
            </div>
          </Waypoint>
        )}
      </article>
      {news?.related?.length > 0 && <RelatedNews related={news.related} />}
      {news?.masterID && !isPreview && (
        <NewsComments
          newsId={news.masterID}
          commentsCount={news.commentsCount}
          hubName={news.hub?.name}
        />
      )}
    </div>
  );
};

export default NewsDetail;
