import classNames from "classnames";
import Photogallery from "./photogallery";
import VideoPlayer from "./video-player";
import InternalMenu from "./internal-menu";
import Content from "./content";
import Translate from "./translate";
import Documents from "./documents";
import { useState } from "react";
import useWaypoints, {
  DOCUMENTS_WAYPOINT,
  PHOTOGALLERY_WAYPOINT,
  VIDEOPLAYER_WAYPOINT,
} from "../hooks/useWaypoints";
import useHeaderBreakpointOffsets from "../hooks/useHeaderBreakpointOffsets";
import IndexBar from "./index-bar";
import { Waypoint } from "react-waypoint";
import useBreakpoint from "../hooks/useBreakpoint";
import useScrollPadding from "../hooks/useScrollPadding";

const ColdPageContent = ({ page, pageContent, loading, internalMenuOpen, setInternalMenuOpen }) => {
  const waypoints = useWaypoints(pageContent);
  const offsets = useHeaderBreakpointOffsets();
  useScrollPadding();
  const [activeWaypoint, setActiveWaypoint] = useState("");
  const isMobile = useBreakpoint(767);

  return (
    <div>
      {!isMobile && (
        <IndexBar
          activeWaypoint={activeWaypoint}
          setActiveWaypoint={setActiveWaypoint}
          waypoints={waypoints}
          className={"index-bar--coldpage"}
        />
      )}
      <main className={classNames("page coldpage text-center", loading && "loading-skeleton")}>
        <div
          className={classNames(
            "page__header wrapper-small",
            !!page?.siblings?.length && "page__header--menu"
          )}
        >
          <h1 className="page__title wrapper-small loading-highlighted">
            <Translate text={page?.label || pageContent?.title} />
          </h1>
          {!!page?.siblings?.length && (
            <InternalMenu
              page={page}
              mobileMenu={internalMenuOpen}
              setMobileMenu={setInternalMenuOpen}
            />
          )}
        </div>
        {isMobile && (
          <IndexBar
            activeWaypoint={activeWaypoint}
            setActiveWaypoint={setActiveWaypoint}
            waypoints={waypoints}
            className={"index-bar--coldpage"}
          />
        )}

        <Waypoint
          onEnter={() => {
            setActiveWaypoint("");
          }}
        >
          <div></div>
        </Waypoint>
        <Content news={pageContent} className="coldpage__content text wrapper-small" />
        {pageContent?.photoGallery?.filter((item) => item?.cropUrlTemplate)?.length > 0 && (
          <Waypoint
            onEnter={() => {
              setActiveWaypoint(PHOTOGALLERY_WAYPOINT);
            }}
            topOffset={offsets.top}
            bottomOffset={offsets.bottom}
          >
            <div id={PHOTOGALLERY_WAYPOINT}>
              <Photogallery
                photogallery={pageContent.photoGallery?.filter((item) => item?.cropUrlTemplate)}
              />
            </div>
          </Waypoint>
        )}
        {pageContent?.video?.data?.uri && (
          <Waypoint
            onEnter={() => {
              setActiveWaypoint(VIDEOPLAYER_WAYPOINT);
            }}
            topOffset={offsets.top}
            bottomOffset={offsets.bottom}
          >
            <div id={VIDEOPLAYER_WAYPOINT}>
              <VideoPlayer video={pageContent.video} />
            </div>
          </Waypoint>
        )}
        {pageContent?.attachments?.length > 0 && (
          <Waypoint
            onEnter={() => {
              setActiveWaypoint(DOCUMENTS_WAYPOINT);
            }}
            topOffset={offsets.top}
            bottomOffset={offsets.bottom}
          >
            <div id={DOCUMENTS_WAYPOINT}>
              <Documents resourceId={pageContent.id} documents={pageContent.attachments} />
            </div>
          </Waypoint>
        )}
      </main>
    </div>
  );
};

export default ColdPageContent;
