import React, { createContext, useState } from "react";

const HomeScreenPromptContext = createContext({
  showHomeScreenPrompt: false,
  setShowHomeScreenPrompt: () => undefined,
});

const HomeScreenPromptProvider = ({ children }) => {
  const [showHomeScreenPrompt, setShowHomeScreenPrompt] = useState(false);

  return (
    <HomeScreenPromptContext.Provider
      value={{
        setShowHomeScreenPrompt: setShowHomeScreenPrompt,
        showHomeScreenPrompt: showHomeScreenPrompt,
      }}
    >
      {children}
    </HomeScreenPromptContext.Provider>
  );
};

export { HomeScreenPromptContext, HomeScreenPromptProvider };
